export const New = () => {
  return (
    <div className="new">
      <p className="new__title">Какие новости?</p>
      <p className="new__description">Оновлено розділ Бонусів та Акцій</p>
      <p className="new__description">
        Додано місце розташування найближчого Відділення, Банкомату або
        Терміналу на карті.
      </p>
      <p className="new__description">Додана підтримка Face ID та Touch ID.</p>
      <p className="new__description">
        Виправлена ​​помилка з push-повідомленнями, яка виникала у деяких
        користувачів Android 10.
      </p>
      <p className="new__description">
        Виправлена ​​проблема, через яку текст в налаштуваннях був дуже дрібним
        для користувачів Android.
      </p>
    </div>
  );
};
